<template>
  <div id="no-hidden-dialog" class="ql-snow no-hidden-dialog">
      <div id="document-wrap" class="document-wrap">
        <el-img-viewer
          v-if="isViewerVisible"
          class="viewer"
          :url-list="nowImgsrc"
          :on-close="closeImgViewer"
        ></el-img-viewer>
        <div id="document-content" @click="showImage">
          <div v-html="data" @click="checktext" id="show-tiptap-docunent-zdh"></div>
        </div>
        <div id="toolbar-container" class="toolbar-container"></div>
        <div id="hide-wrap" class="hide-wrap"></div>
      </div>
    <div class="question-button" @click="showQuestionDialog">
      <span>
        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content">{{ $t("pubKnowledge.RaiseQuestion") }}</div>
          <i
            class="el-icon-question"
            style="
              font-size: 24px;
              vertical-align: middle;
              color: rgba(128, 128, 128, 1);
            "
          ></i>
        </el-tooltip>
      </span>
    </div>
    <div id="menu">
      <div>
        <div
          v-for="(item, index) in rightMenu"
          :key="index"
          @click="menuClick(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
    <el-dialog
      :visible.sync="questionDialogVisible"
      :title="$t('pubKnowledge.SubmitQuestion')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="questionForm"
        :model="questionForm"
        :rules="rules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          :label="$t('pubKnowledge.QuestionDescription')"
          prop="content"
        >
          <el-input v-model="questionForm.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('pubKnowledge.PhoneNumber')"
          prop="phoneNumber"
        >
          <el-input v-model="questionForm.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pubKnowledge.Email')" prop="email">
          <el-input v-model="questionForm.email"></el-input>
        </el-form-item>
      </el-form>
      <el-alert
        :title="$t('pubKnowledge.RequireResponseContact')"
        type="info"
        show-icon
        :closable="false"
      >
      </el-alert>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="questionCancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="questionComfrirm"
          >{{ $t("pubKnowledge.Submit") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      id="registerDialog"
      :modal="false"
      :visible.sync="registerVisible"
      width="580px"
    >
      <span>{{ $t("pubKnowledge.registerTip") }}</span>
      <span slot="footer" class="registerDialog-footer">
        <el-button @click="registerVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="goRegister">{{
          $t("addLogin.register.title")
        }}</el-button>
      </span>
    </el-dialog>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import { ulid } from "ulid";
import { sendQuestions } from "@/network/knowledge";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    internetToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: "",
      outerWidth: 0,
      nowImgsrc: [],
      isViewerVisible: false,
      questionDialogVisible: false,
      questionForm: {
        content: "",
        email: "",
        phoneNumber: "",
      },
      rules: {
        content: [
          {
            required: true,
            message: this.$t("pubKnowledge.FillQuestionDescription"),
            trigger: "blur",
          },
        ],
      },
      // 上次定位的id
      lastHash: "",
      rightMenu: [
        {
          label: this.$t("knowledge.GenerateSharingLink"),
        },
      ],
      registerVisible: false,
      timer: null,
    };
  },
  watch: {
    content(newval) {
      this.gethtml();
    },
    $route(val) {
      if (val.hash) {
        this.locationElement(val.hash);
      }
    },
  },
  mounted() {
    this.initQuill();
    this.listenerwidth();
    this.gethtml();
    this.initRightClick();
    const goTop = document.getElementsByClassName("goTop")[0];
    goTop.style.display = "none";
  },
  beforeDestroy() {
    let scrollContent = document.getElementById("no-hidden-dialog");
    if (scrollContent) {
      scrollContent.removeEventListener(
        "scroll",
        this.debounceScroll(scrollContent, 150)
      );
    }
  },
  methods: {
    checktext(event) {
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;

      if (
        selection.toString() != "" &&
        (commonAncestor.id == undefined || commonAncestor.id != "")
      ) {
        this.setDownposition();
        event.stopPropagation();
      }
    },
    initRightClick() {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("document-content");
      const wrap2 = document.getElementById("document-wrap");
      wrap.oncontextmenu = function (evt) {
        self.setDownposition(evt);
      };
      wrap2.onclick = function () {
        self.lineId
          ? (document.getElementById(self.lineId).style.backgroundColor =
              "#fff")
          : "";
        menu.style.display = "none";
      };
    },
    setDownposition(evt) {
      const self = this;
      const menu = document.getElementById("menu");
      const wrap = document.getElementById("document-content");
      const wrap2 = document.getElementById("document-wrap");
      // 自定义body元素的鼠标事件处理函数
      const e = evt || window.event;
      e.preventDefault(); //阻止系统右键菜单
      // 显示自定义的菜单调整位置
      self.lineId
        ? (document.getElementById(self.lineId).style.backgroundColor = "#fff")
        : "";
      self.lineId = e.target.id ? e.target.id : e.target.parentNode.id;
      document.getElementById(self.lineId).style.backgroundColor = "#f0f7ff";
      if (self.lineId) {
        menu.style.display = "block";
        menu.style.left = e.clientX + "px";
        menu.style.top = e.clientY + wrap.scrollTop + "px";
      }
    },

    // 展示提出问题弹窗
    showQuestionDialog() {
      this.questionDialogVisible = true;
      this.questionForm = {
        content: "",
        email: "",
        phoneNumber: "",
      };
    },
    questionComfrirm() {
      this.$refs.questionForm.validate((valid) => {
        if (valid) {
          const params = {
            pathId: this.$route.params.pathId,
            data: this.questionForm,
          };
          sendQuestions(params).then((res) => {
            this.questionDialogVisible = false;
            this.$message({
              type: "success",
              message: this.$t("pubKnowledge.QuestionSubmittedSuccessfully"),
            });
          });
        }
      });
    },
    questionCancel() {
      this.questionDialogVisible = false;
    },
    showImage(event) {
      if (event.target.localName === "img") {
        this.nowImgsrc = [event.target.currentSrc];
        this.isViewerVisible = true;
      }
      if (event.target.id.split("#")[0] === "link") {
        let l = event.target.attributes.link.value;
        console.log();

        window.open(
          (l.slice(0, 4) == "http" ? "" : "//") +
            event.target.attributes.link.value,
          "_blank"
        );
      }
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    // 监听宽度变化
    listenerwidth() {
      const self = this;
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((item) => {
          self.outerWidth = item.borderBoxSize[0].inlineSize;
        });
      });
      let content = document.getElementById("document-content");
      this.observer.observe(content);
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap", {
        modules: {
          toolbar: "#toolbar-container",
          history: {
            delay: 2000,
            maxStack: 500,
          },
        },
        theme: "snow",
      });
    },
    gethtml() {
      if (this.content) {
        // const delta = JSON.parse(this.content);
        // this.quill.setContents(delta);
        // this.data = this.quill.root.innerHTML;
        this.data =this.content
        this.$nextTick(() => {
          this.locationElement();
          let scrollContent = document.getElementById("no-hidden-dialog");
          if (scrollContent) {
            scrollContent.addEventListener(
              "scroll",
              this.debounceScroll(scrollContent, 80)
            );
          }
        });
      } else {
        this.data = "";
      }
    },
    debounceScroll(dom, delay) {
      const that = this;
      return function () {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        let scrollContent = dom;
        this.timer = setTimeout(() => {
          const clientHeight = scrollContent.clientHeight;
          const scrollTop = scrollContent.scrollTop;
          const scrollHeight = scrollContent.scrollHeight;
          if (Math.ceil(clientHeight + scrollTop) >= scrollHeight) {
            that.registerVisible = true;
          }
        }, delay);
      };
    },
    // 定位参数dom
    locationElement(hash) {
      if ((this.$route.query && this.$route.query.elementId) || hash) {
        this.lastHash
          ? document
              .getElementById(this.lastHash)
              .classList.remove("position_dom")
          : "";
        const id = hash ? hash.split("#")[1] : this.$route.query.elementId;
        const dom = document.getElementById(id);
        if (dom) {
          dom.classList.add("position_dom");
        }
        window.location.href = "#" + id;
        this.lastHash = id;
      }
    },
    // 右键菜单点击事件
    menuClick(index) {
      switch (index) {
        case 0:
          if (this.lineId) {
            const link = window.location.href.split("?")[0];
            const query = `?elementId=${this.lineId}`;
            let text1 = this.$refs["text_area"];
            text1.innerText = link + query;
            text1.select();
            document.execCommand("copy");
            const menu = document.getElementById("menu");
            menu.style.display = "none";
            this.$message({
              type: "success",
              message: this.$t("knowledge.copied"),
            });
          }
          break;
      }
    },
    goRegister() {
      const { href } = this.$router.resolve({
        path: `/login/register`,
      });
      window.open(href, "_blank");
      this.registerVisible = false;
    },
    goTop() {
      const doc = document.getElementById("no-hidden-dialog");
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

.at_position {
  position: absolute;
}
#toolbar-container2 {
  display: none;
}
#editor-container2 {
  width: 100%;
  border-top: 1px solid #ccc;
}
.ql-snow,
.ql-edit {
  width: 65%;
  flex-grow: 1;
  overflow-y: scroll;
  .ql-editor {
    height: fit-content;
  }
}
.document-wrap {
  width: 100%;
  padding-left: 84px;
  padding-bottom: 40px;
  text-align: left;
  overflow-x: visible;
  position: relative;
  overflow-y: visible;
  .hide-wrap {
    display: none;
  }
  .toolbar-container {
    display: none;
  }
}
#menu {
  display: none;
  position: absolute;
  width: 130px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
::v-deep #registerDialog {
  position: unset !important;
  z-index: -1 !important;
}
::v-deep #registerDialog .el-dialog {
  z-index: 9999;
  position: absolute;
  margin: 0;
  right: 30px;
  bottom: 78px;
  height: 200px;
  .el-dialog__body {
    margin-top: 10px;
    font-size: 20px;
  }
  .el-dialog__footer {
    padding-top: 40px !important;
    .el-button {
      width: 100px;
      height: 40px;
    }
  }
}
::v-deep .my-alink,
::v-deep .my-atitle,
::v-deep .my-adesc,
::v-deep .my-acard_link {
  cursor: pointer;
}
.goTop {
  position: absolute;
  bottom: 110px;
  right: 50px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
<style lang="scss">
#show-tiptap-docunent-zdh {
  font-size: 15px;
  text-align: left;
  color: rgb(23, 23, 23);
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  .mention {
    font-size: 13px;
    background-color: #a9d5ff;
    border-radius: 4px;
    box-decoration-break: clone;
    color: #1873cc;
    padding: 1px 5px;
  }
  img {
    // display: block;
    height: auto;
    // margin: 1.5em 0;
    max-width: 100%;
    width: 500px;

    &.ProseMirror-selectednode {
      outline: 3px solid #0064ff;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
    p {
      margin: 5px 0;
    }
    li {
      align-items: flex-start;
      display: flex;
      align-items: center;

      > label {
        margin-right: 0.5em;
        user-select: none;
        width: 20px;
        input {
          // background: red;
        }
      }

      > div {
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 5px 3px;
    position: absolute;
    top: -2em;
    user-select: none;
    white-space: nowrap;
  }
  ul {
    padding-left: 25px;
  }
  ul li::marker {
    color: #0064ff;
  }
  ol {
    padding-left: 25px;
  }
  ol li::marker {
    color: #0064ff;
  }
  a {
    color: #0064ff;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 2px solid #0064ff;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #3d251414;
    border-top: 2px dashed #cccccc;
    cursor: pointer;
    margin: 10px 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #e3eeff;
    }
  }
  mark {
    // background-color: #faf594;
    border-radius: 0.4em;
    box-decoration-break: clone;
    padding: 0.1em 0.3em;
  }
  // 引用样式
  blockquote {
    margin: 0;
    border-left: 3px solid #e5e7eb;
    // margin: 1.5rem 0;
    padding-left: 10px;
    color: rgb(135, 136, 138);
    p {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }
  pre {
    background: black;
    border-radius: 5px;
    font-family: "Consolas, Monaco", "Andale Mono", "Ubuntu Mono", monospace;
    margin: 10px 0;
    color: white;
    padding: 20px;
    font-size: 14px;

    code {
      background: none;
      color: inherit;
      // font-size: 0.8rem;
      padding: 0;
    }
    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;

    tr{
      height: 25px;
    }

    td,
    th {
      border: 1px solid #dfe1e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin: 0 !important;
      }
    }

    th {
      // background-color: gray;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgb(160 198 255 / 60%);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #0064ff;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  mark {
    border-radius: 0;
    padding: 0;
  }

  .tableWrapper {
    margin: 1.5em 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
.no-hidden-dialog {
  overflow-y: scroll;
  max-height: 90%;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  .el-dialog {
    overflow: visible;
  }
  .question-button {
    position: absolute;
    right: 50px;
    bottom: 50px;
    cursor: pointer;
  }
}
.document-wrap {
  & > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-right: 200px !important;
      /*no*/
    }
  }
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
</style>
