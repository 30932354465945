<template>
  <div class="edit-top-wrap">
    <div class="info-wrap">
      <el-tooltip
        v-ak-tooltip-auto-show
        effect="dark"
        placement="bottom"
        :content="documentInfo ? documentInfo.label : ''"
      >
        <div class="title" @click="dbClick">
          <span v-if="!is_edit">
            {{ documentInfo ? documentInfo.label : "" }}</span
          >
          <el-input
            v-else
            v-model="documentInfo.label"
            type="textarea"
            resize="none"
            @blur="titleBlur"
          ></el-input>
        </div>
      </el-tooltip>
    </div>
    <div class="tool-wrap">
      <div style="display: flex">
        <div>
          <personal-avatar
            avatar=""
            colorid=""
            :nickname="$t('me')"
            :size="40"
            placement="top"
            style="margin-right: 5px"
          ></personal-avatar>
        </div>
        <div>
          <personal-avatar
            v-for="(item, index) in userListNow.slice(0, 5)"
            :key="index"
            :colorid="item.userAccountId"
            :nickname="item.userName"
            :size="40"
            placement="top"
            style="margin-right: 5px"
          ></personal-avatar>
        </div>
        <div v-if="userListNow.length > 5" style="font-size: 20px">...</div>
      </div>
      <div style="float: right">
        <el-button
          style="margin-left: 10px; margin-right: 10px"
          :loading="sending"
          :disabled="sending"
          @click="notEditFn"
        >
          {{ $t("knowledge.Exit") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import vmson from "@/utils/vmson";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
import { updateTitleUnlogin } from "@/network/knowledge";
export default {
  components: {
    PersonalAvatar,
  },
  props: {
    sending: {
      type: Boolean,
      default: false,
    },
    documentInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    userListNow: {
      type: Array,
      default() {
        return [];
      },
    },
    internetToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_edit: false,
    };
  },
  watch: {
    userListNow(val) {},
  },
  computed: {
    ...mapGetters(["user_list", "userAccountId"]),
  },
  methods: {
    dbClick() {
      this.is_edit = true;
    },
    titleBlur() {
      if (this.documentInfo.label === "") {
        this.$message.error(this.$t("knowledge.articleTitle"));
        return;
      }
      this.is_edit = false;
      const params = {
        pathId: this.$route.params.pathId,
        data: {
          title: this.documentInfo.label,
          token: this.internetToken,
        },
      };
      updateTitleUnlogin(params).then(() => {});
    },
    notEditFn() {
      if (this.documentInfo.label === "") {
        return;
      }
      vmson.$emit("notEditFn_tiptap");
      this.$emit("notEditFn");
    },
  },
};
</script>

<style scoped lang="scss">
.edit-top-wrap {
  width: 100%;
  height: 140px;
  padding: 20px 90px;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(229, 229, 229, 1);
  text-align: left;
  display: flex;
  .info-wrap {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > .title {
      font-size: 36px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > .sub {
      font-size: 16px;
      font-weight: 400;
      color: rgba(166, 166, 166, 1);
      & > span {
        margin-right: 60px;
      }
    }
  }
  .tool-wrap {
    width: 40%;
    display: flex;
    align-items: center;
    padding-left: 88px;
    justify-content: flex-end;
  }
}
</style>
